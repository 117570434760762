import React, { Component } from 'react'
import Helmet from 'react-helmet'
import '../../assets/sass/styles.sass'
import config from '../../../config'
import NavBar from '../NavBar'
import Footer from '../Footer'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = { isActive: false, subIsActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.toggleSubNavbar = this.toggleSubNavbar.bind(this)
  }

  toggleNavbar () {
    this.setState({ isActive: !this.state.isActive })
  }

  toggleSubNavbar () {
    this.setState({ subIsActive: !this.state.subIsActive })
  }

  render () {
    return (
      <div id='layout-wrapper'>
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name='description' content={config.siteDescription} />
        </Helmet>
        <NavBar isActive={this.state.isActive} toggleNavbar={() => this.toggleNavbar()} 
         subIsActive={this.state.subIsActive} toggleSubNavbar={() => this.toggleSubNavbar()}/>
        <div id='content-wrapper'>
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
