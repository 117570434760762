module.exports = {
  siteTitle: 'PPE4ALL', // Site title.
  siteTitleAlt: 'PPE for free', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest. 
  siteUrl: 'https://www.ppe4all.net', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. 
  siteDescription: 'Request for PPE anywhere in the United States', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '402761306916214', // FB Application ID for using app insights
  googleTagManagerID: 'GTM-5MQL4NJ', // GTM tracking ID.
  disqusShortname: 'ppe4all', // Disqus shortname.
  userName: 'Dave Jha',
  userTwitter: '@davejha',
  userLocation: 'Los Angeles, CA',
  userDescription: '',
  copyright: 'Copyright © PPE4ALL 2020.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#71db77', // Used for setting manifest and progress theme colors.
  backgroundColor: '#222222', // Used for setting manifest background color.
}
