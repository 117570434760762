import React from 'react'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'
import './Header.css'


class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hasScrolled: false,
      hasClickedMenu: false,
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleClick = (event) => {
    var element = document.getElementById('note')
    element.style.display = 'none';
    var navbarMenu = document.getElementById('navMenu');
    var navbarBrand = document.getElementById('navbar-brand');
    navbarMenu.style.padding = "0px";
    navbarBrand.style.padding = "0px";
    this.setState({ hasClickedMenu: true })
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  subMenu = (event) => {
    var element = document.getElementById('submenu');
    if (element.style.display == 'none') {
      element.style.display = 'block';
    }
    else {
      element.style.display = 'none';
    }
  }

  render () {
    return (
      <div>
        <div className={this.state.hasScrolled ? 'alert alertScrolled is-fixed-top' : 'alert is-fixed-top'} aria-label='main navigation'>
          <Link style={{ color: 'white', fontWeight: 'bolder' }} to='/donate'>The demand for PPE continues, and we need your support.</Link>
        </div>
        <nav className={this.state.hasScrolled ? 'navbar HeaderScrolled is-fixed-top' : 'navbar is-fixed-top'} aria-label='main navigation' style={{ top: '65px' }}>
          <div className='navbar-brand' id='navbar-brand'>
            <Link to='/'><img src={require('../../assets/img/ppe4all.png')} width='90' /></Link>
            <button
              className={`button navbar-burger ${this.props.isActive ? 'is-active' : ''}`}
              data-target='navMenu'
              onClick={this.props.toggleNavbar}>
              <span />
              <span />
              <span />
            </button>
          </div>
          <div className={`navbar-menu ${this.props.isActive ? 'is-active' : ''}`} id='navMenu'>
            <div className='navbar-end'>
              <div className={this.props.isActive ? 'do-not-display' : 'navbar-item has-dropdown is-hoverable is-boxed'}>
                <a className='navbar-link'>
                  About Us
                </a>
                <div className='navbar-dropdown'>
                  <hr className='navbar-divider' />
                  <Link className='navbar-item' to='/about'>
                    Our Team
                  </Link>
                  <Link className='navbar-item' to='/stories'>
                    Core Stories
                  </Link>
                </div>
              </div>
              <div className={this.props.isActive ? '' : 'do-not-display'}>
                <div>
                  <a className='navbar-item' onClick={this.props.toggleSubNavbar}>
                    About Us
                  </a>
                  <div className={this.props.subIsActive ? '' : 'do-not-display'}>
                    <Link className='navbar-item submenu-item' to='/about'>
                      Our Team
                    </Link>
                    <Link className='navbar-item submenu-item' to='/stories'>
                      Core Stories
                    </Link>
                  </div>
                </div>
              </div>
              <Link className='navbar-item' to='/cause'>
                Our Story
              </Link>
              <Link className='navbar-item' to='/request'>
                Request PPE
              </Link>
              <div className={this.props.isActive ? 'do-not-display' : 'navbar-item has-dropdown is-hoverable is-boxed'}>
                <a className='navbar-link'>
                  Get Involved
                </a>
                <div className='navbar-dropdown'>
                  <hr className='navbar-divider' />
                  <Link className='navbar-item' to='/give'>
                    Give PPE
                  </Link>
                  <Link className='navbar-item' to='/volunteer'>
                    Volunteer
                  </Link>
                </div>
              </div>
              <div className={this.props.isActive ? '' : 'do-not-display'}>
                <Link className='navbar-item' to='/give'>
                  Give
                </Link>
                <Link className='navbar-item' to='/volunteer'>
                  Volunteer
                </Link>
                <Link className='navbar-item' to='/jobs'>
                  Jobs
                </Link>
              </div>
              <Link className='navbar-item' id='donatebutton' to='/donate/'><button>Donate</button></Link>
              <SocialIcon className='navbar-item' id='social' style={{ height: 25, width: 25 }} bgColor='transparent' fgColor='white' url='https://www.facebook.com/PPE4ALL' />
              <SocialIcon className='navbar-item' id='social' style={{ height: 25, width: 25 }} bgColor='transparent' fgColor='white' url='https://www.instagram.com/ppe4.all/' />
              <SocialIcon className='navbar-item' id='social' style={{ height: 25, width: 25 }} bgColor='transparent' fgColor='white' url='https://twitter.com/ppe4all/' />
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header

/* Notification Bar:

<div id={this.state.hasClickedMenu ? 'doNotDisplay' : 'note'}>
            <p> 
              <button id="close" onClick={this.handleClick}>
                <span className="icon has-text-white is-centered">
                  <FontAwesomeIcon icon={faTimesCircle} size={"1x"} />
                </span>
              </button> 
              PPE4NYC is now providing face shields for protestors in New York City. 
              <a href="https://ppe4nyc.org/protestors" id="donatelink">
                <u>Find out more →</u>
              </a>  
            </p> 
          </div>

          */