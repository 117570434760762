import React from 'react'
import config from '../../../config'
import styled from 'styled-components'
import './index.css'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


const LinkGroup = styled.div`
  max-width: 800px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-row-gap: 50px;
  @media (max-width: 640px) {
    grid-template-columns: repeat(2, auto);
    width: 100%;
    grid-row-gap: 20px;
  }
`

const FooterGroup = styled.div`
  background: #333333;
  padding: 10px 0;
  display: grid;
  grid-gap: 5px;
`

const Text = styled.p`
  font-size: 12px;
  font-weight: 600 !important;
  max-width: 320;
  color: white;
  margin: 0 auto;
`
const Container = styled.div`
  font-family: 'Libre Franklin', sans-serif;
  padding: 1rem 20%;
  text-transform: uppercase;
  background: #333333;
  float: center;
  padding: 0px 10%;
`
const CalltoAction = styled.div`
  float: center;
  
`

const Footer = () => {
  return (
    <FooterGroup>
      <CalltoAction>
        <div className = "callToActionColumn1">
          <p>We need your help as we continue to supply PPE across the country and world.</p>
        </div>
        <div className = "callToActionColumn2">
          <div className = "footer-button">
            <a className = "navebar-item" id = "donatebutton" href = "/donate/">
              <button>Donate 
              <FontAwesomeIcon className="ml-3" size="sm" icon={faArrowRight} />

              </button>
            </a>
          </div>
        </div>
      </CalltoAction>
      <Container>
      <hr className="solid"/>
        <div className = "row">
          <div className = "firstColumn is-centered">
            <div className = "menu">
              <Link className="logo" to="/"><img src={require('../../assets/img/PPE4ALL-Logo-GREY.png')} width="150" /></Link>
                <Text id="copyright">
                  <br></br>
                  {config.copyright}
                </Text>
              <div className='content'>
                <SocialIcon id="social" className = "footerSocial" bgColor="transparent" fgColor="white" url="https://www.facebook.com/PPE4ALL"/>
                <SocialIcon id="social" className = "footerSocial" bgColor="transparent" fgColor="white" url="https://www.instagram.com/ppe4.all/"/>
                <SocialIcon id="social" className = "footerSocial" bgColor="transparent" fgColor="white" url="https://twitter.com/ppe4all/"/>
              </div>
              <iframe src="https://status.ppe4all.net/embed-status/dark-sm" width="230" height="61" 
                      frameBorder="0" scrolling="no"></iframe>
            </div>         
          </div>
            <div className = "otherColumn">
              <h2>Company</h2>
                <ul>
                  <li><Link id="footerlink" to="/about/">About Us</Link></li>
                  <li><Link id="footerlink" to="/cause/">Our Story</Link></li>
                  <li><Link id="footerlink" to="/contact/">Contact Us</Link></li>
                  <li><Link id="footerlink" to="/privacy/">Privacy</Link></li>
                  <li><Link id="footerlink" to="/terms/">Terms</Link></li>

                </ul>
            </div>
            <div className = "otherColumn">
              <h2>Get Involved</h2>
                <ul>
                  <li><Link id="footerlink" to="/make/">Make PPE</Link></li>
                  <li><Link id="footerlink" to="/give/">Give PPE</Link></li>
                  <li><Link id = "footerlink" to="/request/">Request PPE</Link></li>
                  <li><Link id="footerlink" to="/volunteer/">Volunteer</Link></li>
                  <li><Link id = "footerlink" to="/donate/">Donate</Link></li>
                </ul>
            </div>
          </div>
      </Container>
    </FooterGroup>
  )
}

export default Footer
